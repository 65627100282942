export const boardElement = document.getElementById('board');
export const submitButtonElement = document.getElementById('submit');
export const shareButtonElement = document.getElementById('share');
export const gameOverElement = document.getElementById('game-over');
export const gameOverReasonElement = document.getElementById('game-over-reason');
export const scoreElement = document.getElementById('score');
export const messageElement = document.getElementById('message');
export const keyboardElement = document.getElementById('keyboard');
export const aiThoughtsElement = document.getElementById('ai-thoughts');
export const tutorialElement = document.getElementById('tutorial');
export const tooltipElement = document.getElementById('tooltip');
export const tooltipPopupElement = document.getElementById('tooltip-popup');
export const settingsButtonElement = document.getElementById('settings-button');
export const settingsPopupElement = document.getElementById('settings-popup');
export const highContrastModeElement = document.getElementById('high-contrast-mode');
export const controlsElement = document.getElementById('controls');
export const timeLeftElement = document.getElementById('time-left');
export const highScoreElement = document.getElementById('high-score');
export const whyDidILosePopupButtonElement = document.getElementById('why-did-i-lose-popup-button');
export const whyDidILosePopupElement = document.getElementById('why-did-i-lose-popup');
export const whyDidILoseInputElement = document.getElementById('why-did-i-lose-input');
export const whyDidILoseButtonElement = document.getElementById('why-did-i-lose-button');
export const whyDidILoseOutputElement = document.getElementById('why-did-i-lose-output');
export const buttons = Array.from(document.getElementById('buttons').children);
